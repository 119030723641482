import request from '@/utils/request'

export function companyList(data) { // 18.企业用户列表
  return request({
    url: 'User/companyList',
    method: 'post',
    data
  })
}
export function companyInfo(data) { // 19.企业用户信息
  return request({
    url: 'User/companyInfo',
    method: 'post',
    data
  })
}
export function evaluationList(data) { // 20.评价列表
  return request({
    url: 'Evaluation/index',
    method: 'post',
    data
  })
}
export function myInfo(data) { // 16.获取我的信息
  return request({
    url: 'User/my',
    method: 'post',
    data
  })
}
