<template>
  <div class="container">
    <div class="searchBack flex_jc_ac">
      <Search :keyval="keyword" :searchType="'menber'" @searchClick="searchClick" />
    </div>
    <Catalogue :catalogueType="1" @searchProjectTwo="searchProjectTwo" />
    <RegionalMembers :classifyList="cityMembersList" :cityId="city_id"
    @changeSecondClassify="changeSecondClassify" />
    <FilterList :filter="0" @changeFilter="changeFilter" />
    <CompanyList :list="companyList" />
    <div class="pagin">
      <Pagination :total="totalCount" :pageSize="8" @changeCurrentPage="changeCurrentPage" v-if="companyList.length" />
    </div>
  </div>
</template>

<script>
import FilterList from '@/components/sort/filterList'
import Catalogue from '@/components/sort/catalogue'
import RegionalMembers from '@/components/sort/regionalMembers'
import Search from '@/components/common/search'
import CompanyList from '@/components/sort/companyList'
import {companyList} from '@/api/member/member'
import { cityList } from '@/api/index/index'
import Pagination from '@/components/common/pagination'
export default {
  name: 'memberShow',
  components: {
    Search,
    RegionalMembers,
    CompanyList,
    Catalogue,
    Pagination,
    FilterList
  },
  data() {
    return {
      cityMembersList: [],
      companyList: [],
      cate_id_one: '',
      cate_id_two: '',
      keyword: '',
      city_id: 0,
      page: 1,
      order: 1, // 最新最热
      totalCount: 0
    }
  },
  mounted() {
    this.keyword = this.$route.params.keyword
    this.city_id = this.$route.params.city_id
    this.rcityListegionalMmbers()
    this.companyClick()
  },
  methods: {
    changeFilter(sortId) {
      this.order = sortId
      this.companyClick()
    },
    searchClick(key) {
      this.keyword = key
      this.companyClick()
    },
    changeCurrentPage(value) {
      this.page = value
      this.companyClick()
    },
    searchProjectTwo(id) {
      // console.log('id', id)
      this.cate_id_two = id
      this.companyClick()
    },
    rcityListegionalMmbers() {
      const data = {
        pid: 0
      }
      cityList(data).then(res => {
        // console.log('地区', res.data)
        this.cityMembersList = res.data
      })
    },
    changeSecondClassify(id) { // 地区会员 返回id
      this.city_id = id
      this.companyClick()
    },
    companyClick() {
      const data = {
        page: this.page,
        psize: 8,
        cate_id_two: this.cate_id_two,
        city_id: this.city_id,
        order: this.order,
        keyword: this.keyword
      }
      companyList(data).then(res => {
        // console.log('11', res.data)
        this.totalCount = res.data.count
        this.companyList = res.data.list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container{
    width: 1200px;
    margin: 20px auto 0;
    .pagin{
      margin: 40px auto;
    }
    .searchBack{
      width: 868px;
      background: #fff;
      padding: 40px 166px;
    }
    .classifyList{
      background: #fff;
      padding: 0 40px;
    }
  }
</style>
