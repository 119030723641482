<template>
  <div class="container">
    <div class="flex companyBox" v-for="(item,index) in list" :key="index" @click="memberInformaClick(item.id)">
      <img class="img" :src="item.avatarUrl || ''" />
      <div class="company">
        <div class="title flex">
          {{item.nickName}}
          <span :class="item.certification_type === 1 ? 'ponsanl' : 'qiern'">{{item.certification_type === 1 ? '个' : '企'}}</span>
        </div>
        <div class="connet ellipsis-row">{{item.introduction}}</div>
        <div class="flex_jcsb_ac classBox">
          <div class="flex class">
            <div class="ification" v-for="(items,indexs) in item.cate_name" :key="indexs">{{items.name}}</div>
          </div>
          <div class="eye">
            <i class="el-icon-view"></i>
            <span>{{item.view}}</span>
          </div>
        </div>
      </div>
    </div>
     <el-empty v-if="!list.length"></el-empty>
  </div>
</template>

<script>
export default {
  name: 'companyList',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    memberInformaClick(id) {
      if (!JSON.parse(localStorage.getItem('userInfo'))) {
        this.$message.warning('请先登录')
        localStorage.setItem('loginType', 0);
        this.$router.push('/login')
        return
      }
      this.$router.push({name: 'memberInformation', query: {id}})
    }
  }
}
</script>

<style lang="scss" scoped>
  .container{
    margin: 4px 0;
    .companyBox{
      padding: 18px 24px 18px 16px;
      background: #FFF;
      margin-top: 12px;
      cursor: pointer;
      border:1px solid transparent;
      box-sizing: border-box;
      &:hover{
        border: 1px solid #1166CF;
        background: #F8FBFF;
      }
      .company{
        margin-left: 24px;
        flex: 1;
        .classBox{
          margin-top: 8px;
          .eye{
            .el-icon-view{
              color: #666;
              font-size: 16px;
            }
            span{
              font-size: 16px;
            }
          }
          .class{
            flex-wrap: wrap;
            .ification{
              background: #EDF5FF;
              border-radius: 4px;
              padding: 7px 10px;
              color: #1166CF;
              font-size: 14px;
              margin-right: 12px;
            }
          }
        }
        .connet{
          line-height: 26px;
          font-size: 16px;
          color: #666;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          margin-top: 30px;
        }
        .title{
          font-size: 18px;
          span{
            width: 22px;
            height: 22px;
            border-radius: 50%;
            margin-left: 9px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #fff;
          }
          .ponsanl{
            background: #449CED;
          }
          .qiern{
            background: #FFB400;
          }
        }
      }
      .img{
        width: 144px;
        height: 144px;
        object-fit: cover;
      }
    }
  }
</style>
